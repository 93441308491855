import Button from "@mui/material/Button";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';

const {Component} = require("react");

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleClickOpen() {
        this.setState({
            open: true
        });
    };

    handleClose() {
        this.setState({
            open: false
        });
    };


    handleRestart() {
        this.props.client.send(JSON.stringify(
            {
                action: "finish",
                payload: {}
            }
        ))
    }

    render() {
        return (
            <div style={{position: 'absolute', top: '5px', left: '5px'}}>
                <IconButton aria-label="setting" size="large" onClick={() => {
                    this.handleClickOpen()
                }}>
                    <SettingsIcon/>
                </IconButton>
                <Dialog open={this.state.open} onClose={() => {
                    this.handleClose()
                }}>
                    <DialogTitle>Setting</DialogTitle>
                    <DialogContent>
                        <Button fullWidth onClick={() => {this.handleRestart()}} variant="outlined">Restart</Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.handleClose()
                        }}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default Setting