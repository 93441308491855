import {Component} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";

let QRCode = require('qrcode.react');


class PlayerQrCode extends Component {
    render() {
        const playerLink = process.env.REACT_APP_CLIENT_PORT ?
            `http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_CLIENT_PORT}/player`
            : `${window.location.protocol}//${window.location.host}/player`

        return (
            <Container maxWidth="xs">
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    marginTop="50px"
                    color="text.primary"
                    gutterBottom
                >
                    Stolen Quiz
                </Typography>
                <Card>
                    <QRCode width="100%" height="100%" value={playerLink} renderAs="svg"/>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Open on your device
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {playerLink}
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        );
    }
}

export default PlayerQrCode