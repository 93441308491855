import React, {Component} from "react";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

class SelectQuizVariant extends Component {
    selectQuiz(quiz) {

        this.props.client.send(JSON.stringify(
            {
                action: "play",
                payload: {
                    current: this.props.quizState.payload.progress.current + 1,
                    total: this.props.quizState.payload.progress.total,
                    quizId: quiz.id,
                }
            }
        ))
    }

    render() {
        return (
            <Container maxWidth="xs">
                <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    marginTop="50px"
                    color="text.primary"
                    gutterBottom
                >
                    Select topic
                </Typography>
                <List>
                    {
                        this.props.quizState.payload.quizVariants
                            .map((quiz, i) => {
                                return <ListItemButton
                                    key={i}
                                    divider
                                    disablePadding
                                    onClick={() => {
                                        this.selectQuiz(quiz)
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt=""
                                                src={
                                                    window.location.protocol === "http:"
                                                        ? `http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_API_PORT}${quiz.image}`
                                                        : `${window.location.protocol}//${window.location.host}${quiz.image}`
                                                }/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={quiz.title}
                                        secondary={
                                            <Typography
                                                sx={{display: 'inline'}}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {quiz.content}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            })
                    }
                </List>
            </Container>
        );
    }
}

export default SelectQuizVariant