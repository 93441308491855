import React, {Component} from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";

class SelectAnswer extends Component {
    sendAnswer(answer) {
        this.props.client.send(JSON.stringify(
            {
                action: "select_answer",
                payload: {
                    answerId: answer.id
                }
            }
        ))
    }

    render() {
        let answers;
        if (this.props.quizState.payload.quiz.question.variant === 0) {
            answers = this.props.quizState.payload.quiz.question.answers;
        } else {
            answers = this.props.quizState.payload.quiz.question.popularAnswers;
        }

        return (
            <Container maxWidth="xs">
                <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    marginTop="50px"
                    color="text.primary"
                    gutterBottom
                >
                    {
                        this.props.quizState.payload.quiz.question.image &&
                        <div><img style={{
                            width: '100%',
                            height: "auto",
                            borderRadius: '10px',
                            margin: '10px 20px 20px 0px'
                        }}
                                  src={
                                      window.location.protocol === "http:"
                                          ? `http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_API_PORT}${this.props.quizState.payload.quiz.question.image}`
                                          : `${window.location.protocol}//${window.location.host}${this.props.quizState.payload.quiz.question.image}`
                                  }
                                  alt=""/></div>
                    }
                    <div dangerouslySetInnerHTML={{ __html: this.props.quizState.payload.quiz.question.content }} />
                </Typography>
                <List>
                    {

                        answers
                            .map((answer, i) => {
                                return <ListItemButton
                                        key={i} divider disablePadding
                                        onClick={() => {
                                            this.sendAnswer(answer)
                                        }}
                                        selected={
                                            answer.playerDeviceIds
                                            && answer.playerDeviceIds
                                                .filter(id => id === this.props.deviceId).length !== 0
                                        }>
                                        <ListItemText primary={<div dangerouslySetInnerHTML={{ __html: answer.content }} />}/>
                                    </ListItemButton>
                            })
                    }
                </List>
            </Container>
        );
    }
}

export default SelectAnswer