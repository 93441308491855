import {Container, Typography} from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import * as color from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import PlayerList from "../player-list/player-list";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import {Stars} from "@mui/icons-material";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

const {Component} = require("react");

class Finish extends Component {
    render() {
        let scoreMap = [];
        this.props.quizState.players = this.props.quizState.players ? this.props.quizState.players : [];
        this.props.quizState.players.forEach(p => {
            let scoreSlice = scoreMap.find(sm => sm.score === p.score);
            if (!scoreSlice) {
                scoreSlice = {
                    score: p.score,
                    players: []
                };

                scoreMap.push(scoreSlice);
            }

            scoreSlice.players.push(p);
        });

        scoreMap.sort((sm1, sm2) => sm2.score - sm1.score);

        return <Container>
            <Typography
                component="h1"
                variant="h2"
                align="center"
                marginTop="50px"
                color="text.primary"
                gutterBottom
            >
                Stolen Quiz<br/><EmojiEventsIcon fontSize='large'/>
            </Typography>
            <Stack
                direction="column"
                alignItems="center"
                spacing={4}
            >
                {
                    scoreMap.map((sm, i) => {
                        return <Stack direction="row"
                                      key={i}
                                      spacing={2}
                                      justifyContent="flex-start">
                            <Typography
                                variant="h3"
                                sx={{marginTop: 'auto'}}
                                margin="auto 0"
                                component="h3">
                                <MilitaryTechIcon fontSize='large'/> {i + 1} <Stars sx={{marginLeft: 2}}
                                                                                    fontSize='large'/> {sm.score}
                            </Typography>
                            {
                                sm.players.map((player, ii) => {
                                    return <Card key={ii} sx={{width: 200}}>
                                        <CardHeader
                                            avatar={

                                                <Avatar {...PlayerList.stringAvatar(player)}
                                                        sx={{bgcolor: color[player.color][500]}}
                                                        aria-label="recipe"/>
                                            }
                                            title={player.name}
                                        />
                                    </Card>
                                })
                            }
                        </Stack>
                    })
            }
            </Stack>
        </Container>
    }
}

export default Finish