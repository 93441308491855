import * as React from "react";
import {Component} from "react";
import {Box, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {playerName: localStorage.getItem('playerName') ?? ''};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        localStorage.setItem('playerName', this.state.playerName);

        this.props.client.send(JSON.stringify(
            {
                action: "register_player",
                payload: {
                    name: this.state.playerName,
                }
            }
        ))
    }

    handleChange(event) {
        this.setState({playerName: event.target.value});
    }

    render() {
        return (
            <Box component="form" onSubmit={this.handleSubmit}>
                <Container maxWidth="xs">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        marginTop="50px"
                        color="text.primary"
                        gutterBottom
                    >
                        Stolen Quiz
                    </Typography>
                    <TextField fullWidth
                               required
                               defaultValue={this.state.playerName}
                               onChange={this.handleChange}
                               label="Enter your name"/>
                    <Button size="large"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                        Sign In
                    </Button>
                </Container>
            </Box>
        );
    }
}

export default SignIn