import React, {Component} from "react";
import {CardContent, Container} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import green from "@mui/material/colors/green";
import CheckIcon from '@mui/icons-material/Check';
import Fade from "@mui/material/Fade";
import Chip from "@mui/material/Chip";
import {Stars} from "@mui/icons-material";

class RoundQuestionShow extends Component {
    constructor(props) {
        super(props);

        let totalSeconds = process.env.REACT_APP_ROUND_TIMEOUT;

        this.state = {
            progress: 0,
            secondsLeft: totalSeconds
        }

        let startTime = (new Date()).getTime();
        let timeout = totalSeconds * 1000;
        let finalTime = (new Date()).getTime() + timeout;

        this.intervalId = setInterval(() => {
            if ((finalTime - (new Date()).getTime()) < 0) {
                clearInterval(this.intervalId)
                this.setState({
                    progress: 100,
                    secondsLeft: 0
                })
                return;
            }

            this.setState({
                progress: ((new Date()).getTime() - startTime) / ((finalTime - startTime) / 100),
                secondsLeft: Math.round((finalTime - (new Date()).getTime()) / 1000)
            })
        }, 100)
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render() {
        return (
            <Container>
                <Box style={{margin: '30px 0', textAlign: 'center'}}>
                    <Chip icon={<Stars />} color="success" label={this.props.quizState.payload.quiz.score} />
                </Box>
                <Box style={{margin: '10px 0', textAlign: 'center'}}>
                    <Box sx={{position: 'relative', display: 'inline-flex'}}>
                        <CircularProgress size={100} sx={{color: green[500]}} variant="determinate"
                                          value={this.state.progress}/>
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="h4" component="div" color="text.primary">
                                {(() => {
                                    if (this.state.secondsLeft) {
                                        return this.state.secondsLeft;
                                    }
                                    return <CheckIcon/>;
                                })()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Fade in={true} timeout={1000}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar sx={{width: 100, height: 100}}
                                    src={
                                        window.location.protocol === "http:"
                                            ? `http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_API_PORT}${this.props.quizState.payload.quiz.image}`
                                            : `${window.location.protocol}//${window.location.host}${this.props.quizState.payload.quiz.image}`
                                    }/>
                        }
                        title={
                            <Typography variant="h6">
                                {this.props.quizState.payload.quiz.title}
                            </Typography>
                        }
                        subheader={this.props.quizState.payload.quiz.content} // todo sub section
                    />
                    <CardContent>
                        <Typography style={{textAlign: 'left', marginTop: '30px'}} variant="h4">
                            {
                                this.props.quizState.payload.quiz.question.image &&
                                <img style={{
                                    float: 'left',
                                    width: '300px',
                                    borderRadius: '10px',
                                    margin: '0px 20px 20px 0px'
                                }}
                                     src={
                                         window.location.protocol === "http:"
                                             ? `http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_API_PORT}${this.props.quizState.payload.quiz.question.image}`
                                             : `${window.location.protocol}//${window.location.host}${this.props.quizState.payload.quiz.question.image}`
                                     } alt=""/>
                            }
                            <div dangerouslySetInnerHTML={{ __html: this.props.quizState.payload.quiz.question.content }} />
                        </Typography>
                    </CardContent>
                </Card>
                </Fade>
            </Container>
        )
    }
}

export default RoundQuestionShow