import * as React from 'react';
import {Component} from 'react';
import {w3cwebsocket as W3CWebSocket} from "websocket";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SignIn from "./sign-in/sign-in";
import {Container, Typography} from "@mui/material";
import ConfirmGameLaunch from "./confirm-game-launch/confirm-game-launch";
import SelectAnswer from "./select-answer/select-answer";
import LookScreen from "./look-screen/look-screen";
import SelectQuizVariant from "./select-quiz-variant/select-quiz-variant";

class Player extends Component {
    constructor(props) {
        super(props);

        let deviceId = localStorage.getItem("deviceId")
        if (deviceId === null) {
            deviceId = Math.random().toString(36).replace(/[^a-z0-9]+/g, '');
            localStorage.setItem("deviceId", deviceId)
        }

        this.deviceId = deviceId;

        this.state = {
            client: this.createClient(),
            clientReadyState: 0,
            deviceId: this.deviceId,
            quizState: {
                state: '',
                payload: {},
                players: null,
            },
        };
    }

    createClient() {
        this.client =
            window.location.protocol === "http:" ?
                new W3CWebSocket(
                    `ws://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_WS_PORT}/ws?device_id=${this.deviceId}`
                )
                : new W3CWebSocket(
                `wss://${window.location.host}/ws?device_id=${this.deviceId}`
                );

        this.client.onopen = () => {
            console.log('WebSocket Client Connected');
            this.setState({
                clientReadyState: this.client.readyState
            })
        };

        this.client.onclose = () => {
            console.log('WebSocket Client Closed');
            setTimeout(() => {
                this.setState({
                    client: this.createClient()
                })
            }, 1000)
            this.setState({
                clientReadyState: this.client.readyState
            })
        };

        this.client.onmessage = (message) => {
            try {
                let data = JSON.parse(message.data);
                this.setState({
                    quizState: data
                })
            } catch {}
        }

        return this.client;
    }

    switchComponent() {
        if (this.state.clientReadyState !== 1) {
            return <Container maxWidth="xs">
                <Typography
                    component="h3"
                    variant="h2"
                    align="center"
                    marginTop="50px"
                    color="text.primary"
                    gutterBottom
                >
                    Connection...
                </Typography>
            </Container>;
        }
        if (this.state.quizState.state === 'players_waiting') {
            let players = this.state.quizState.players ?? [];
            let p = players.find((p) => {
                return p.deviceId === this.deviceId
            })
            if (p) {
                return <ConfirmGameLaunch client={this.state.client} quizState={this.state.quizState} />
            } else {
                return <SignIn client={this.state.client}/>;
            }
        }
        let players = this.state.quizState.players ?? [];
        let p = players.find((p) => {
            return p.deviceId === this.deviceId
        })
        if (!p) {
            return <LookScreen text="The game is already running :("/>
        }

        if (this.state.quizState.state === 'round_answers_waiting') {
            return <SelectAnswer client={this.state.client} deviceId={this.state.deviceId} quizState={this.state.quizState} />
        }
        if (this.state.quizState.state === 'show_quiz_variants' && this.state.quizState.payload.deviceId === this.state.deviceId) {
            return <SelectQuizVariant client={this.state.client} quizState={this.state.quizState} />
        }

        return <LookScreen text="Look at the shared screen"/>
    }

    render() {
        const theme = createTheme({
            palette: {
                mode: 'dark',
            },
        });

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <main>
                    {this.switchComponent()}
                </main>
            </ThemeProvider>
        );
    }
}

export default Player