import React, {Component} from "react";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Container} from "@mui/material";
import Card from "@mui/material/Card";

class ConfirmGameLaunch extends Component {
    clickPlay() {
        this.props.client.send(JSON.stringify(
            {
                action: "play",
                payload: {
                    current: 1,
                    total: parseInt(process.env.REACT_APP_ROUND_QUESTIONS_TOTAL)
                }
            }
        ))
    }

    render() {
        return (
            <Container maxWidth="xs">
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    marginTop="50px"
                    color="text.primary"
                    gutterBottom
                >
                    Stolen Quiz
                </Typography>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Player connected:&nbsp;
                            {
                                this.props.quizState.players
                                    .filter(player => player.status === 'active')
                                    .length
                            }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Press "play" when everyone is ready
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button fullWidth variant="outlined" onClick={() => {this.clickPlay()}} size="large">Play</Button>
                    </CardActions>
                </Card>
            </Container>
        );
    }
}

export default ConfirmGameLaunch