import React, {Component} from "react";
import {Container, Stack} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import green from "@mui/material/colors/green";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import PlayerList from "../player-list/player-list";
import * as color from "@mui/material/colors";

class ShowQuizVariants extends Component {

    constructor(props) {
        super(props);

        let totalSeconds = process.env.REACT_APP_ROUND_TIMEOUT;

        this.state = {
            progress: 0,
            secondsLeft: totalSeconds
        }

        let startTime = (new Date()).getTime();
        let timeout = totalSeconds * 1000;
        let finalTime = (new Date()).getTime() + timeout;

        this.intervalId = setInterval(() => {
            if ((finalTime - (new Date()).getTime()) < 0) {
                clearInterval(this.intervalId)
                this.setState({
                    progress: 100,
                    secondsLeft: 0
                })
                return;
            }

            this.setState({
                progress: ((new Date()).getTime() - startTime) / ((finalTime - startTime) / 100),
                secondsLeft: Math.round((finalTime - (new Date()).getTime()) / 1000)
            })
        }, 100)
    }

    render() {
        return <Container>
            <Box style={{margin: '30px 0', textAlign: 'center'}}>
                <Typography variant="h4" align="center" component="div" color="text.primary">
                    Player {
                        this.props.quizState.players
                            .filter(p => p.deviceId === this.props.quizState.payload.deviceId)
                            .map((player, i) => {
                                return <Avatar
                                    key={i} {...PlayerList.stringAvatar(player, i)}
                                    sx={{bgcolor: color[player.color][500]}}
                                    style={{
                                        display: 'inline-flex',
                                        verticalAlign: 'middle',
                                    }}
                                    aria-label="recipe"/>
                            })
                    } chooses a topic
                </Typography>
            </Box>
            <Box style={{margin: '10px 0', textAlign: 'center'}}>
                <Box sx={{position: 'relative', display: 'inline-flex'}}>
                    <CircularProgress size={100} sx={{color: green[500]}} variant="determinate"
                                      value={this.state.progress}/>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h4" component="div" color="text.primary">
                            {(() => {
                                if (this.state.secondsLeft) {
                                    return this.state.secondsLeft;
                                }
                                return <CheckIcon/>;
                            })()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Stack direction="row" spacing={2}>
                {
                    this.props.quizState.payload.quizVariants
                        .map((quiz, i) => {
                            return <Fade
                                key={i}
                                in={true}
                                style={{transitionDelay: `${500 * i}ms`}}
                                timeout={1000}>
                                <Card sx={{ width: 345 }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={
                                            window.location.protocol === "http:"
                                                ? `http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_API_PORT}${quiz.image}`
                                                : `${window.location.protocol}//${window.location.host}${quiz.image}`
                                        }
                                        alt=""
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {quiz.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {quiz.content}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Fade>
                        })
                }
            </Stack>
        </Container>;
        }
}

export default ShowQuizVariants