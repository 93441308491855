import * as React from 'react';
import {Component} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from "@mui/material/Box";
import {w3cwebsocket as W3CWebSocket} from "websocket";
import PlayerList from "./player-list/player-list";
import {Container, Typography} from "@mui/material";
import PlayerQrCode from "./player-qr-code/player-qr-code";
import RoundQuestionShow from "./round-question-show/round-question-show";
import RoundShowResults from "./round-show-results/round-show-results";
import Progress from "./progress/progress";
import Finish from "./finish/finish";
import ShowQuizVariants from "./show-quiz-variants/show-quiz-variants";
import Setting from "./setting/setting";

class Master extends Component {
    constructor(props) {
        super(props);

        let deviceId = localStorage.getItem("deviceId")
        if (deviceId === null) {
            deviceId = Math.random().toString(36).replace(/[^a-z0-9]+/g, '');
            localStorage.setItem("deviceId", deviceId)
        }

        this.deviceId = deviceId;

        this.state = {
            client: this.createClient(),
            clientReadyState: 0,
            deviceId: this.deviceId,
            quizState: {
                state: '',
                payload: {},
                players: null,
            },
        };
    }

    createClient() {
        this.client =
            window.location.protocol === "http:" ?
                new W3CWebSocket(
                    `ws://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_WS_PORT}/ws?device_id=${this.deviceId}`
                )
                : new W3CWebSocket(
                `wss://${window.location.host}/ws?device_id=${this.deviceId}`
                );

        this.client.onopen = () => {
            console.log('WebSocket Client Connected');
            this.setState({
                clientReadyState: this.client.readyState
            })
        };

        this.client.onclose = () => {
            console.log('WebSocket Client Closed');
            setTimeout(() => {
                this.setState({
                    client: this.createClient()
                })
            }, 1000)
            this.setState({
                clientReadyState: this.client.readyState
            })
        };

        this.client.onmessage = (message) => {
            try {
                let data = JSON.parse(message.data);
                this.setState({
                    quizState: data
                })
            } catch {
            }
        }

        return this.client;
    }

    switchComponent() {
        if (this.state.clientReadyState !== 1) {
            return <Container maxWidth="xs">
                <Typography
                    component="h3"
                    variant="h2"
                    align="center"
                    marginTop="50px"
                    color="text.primary"
                    gutterBottom
                >
                    Connection...
                </Typography>
            </Container>;
        }
        if (this.state.quizState.state === 'players_waiting') {
            return <PlayerQrCode/>
        }
        if (this.state.quizState.state === 'round_answers_waiting') {
            return <RoundQuestionShow quizState={this.state.quizState}/>
        }
        if (this.state.quizState.state === 'round_show_results') {
            return <RoundShowResults quizState={this.state.quizState}/>
        }
        if (this.state.quizState.state === 'show_quiz_variants') {
            return <ShowQuizVariants quizState={this.state.quizState}/>
        }
        if (this.state.quizState.state === 'finish') {
            return <Finish quizState={this.state.quizState}/>
        }
    }

    render() {
        const theme = createTheme({
            palette: {
                mode: 'dark',
            },
        });

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <main>
                    {this.state.clientReadyState === 1 && <Setting client={this.state.client}/>}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100vh',
                        }}
                    >
                        {this.switchComponent()}
                        <Box
                            component="footer"
                            sx={{py: 3, px: 2, mt: 'auto',}}>
                            {
                                this.state.clientReadyState === 1
                                && this.state.quizState.players
                                && this.state.quizState.players.length
                                && this.state.quizState.state !== 'finish'
                                && <PlayerList quizState={this.state.quizState}/>
                            }
                            {
                                (this.state.quizState.state === 'round_answers_waiting'
                                    || this.state.quizState.state === 'round_show_results')
                                && <div style={{padding: '10px'}}><Progress quizState={this.state.quizState}/></div>
                            }
                        </Box>
                    </Box>
                </main>
            </ThemeProvider>
        );
    }
}

export default Master;