import * as React from "react";
import {Component} from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import * as color from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as runes from "runes";
import Badge from "@mui/material/Badge";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {Error, Stars} from "@mui/icons-material";


class PlayerList extends Component {
    static stringAvatar(player) {
        let name = player.name ? runes.substr(player.name, 0, 1).toUpperCase() : '';
        return {
            children: name,
        };
    }

    render() {
        return (
            <Stack direction="row" spacing={2}>
                {
                    this.props.quizState.players
                        .sort((player1, player2) => player2.score - player1.score)
                        .map((player, i) =>
                        {
                            let icon = '';

                            if (player.status !== 'active') {
                                icon = <Error fontSize="small"/>
                            }

                            if (player.status === 'active' && this.props.quizState.state === 'round_answers_waiting') {
                                let answers;
                                if (this.props.quizState.payload.quiz.question.variant === 0) {
                                    answers = this.props.quizState.payload.quiz.question.answers;
                                } else {
                                    answers = this.props.quizState.payload.quiz.question.popularAnswers;
                                }

                                icon = <HourglassBottomIcon fontSize="small"/>
                                answers.forEach((a => {
                                    if (a.playerDeviceIds && a.playerDeviceIds.indexOf(player.deviceId) !== -1) {
                                        icon = ''
                                    }
                                }));
                            }

                            return <Badge key={i}
                                   invisible={icon === ''}
                                   badgeContent={icon}>
                                <Card sx={{width: 200}}>
                                    <CardHeader
                                        avatar={

                                            <Avatar {...PlayerList.stringAvatar(player)}
                                                    sx={{bgcolor: color[player.color][500]}}
                                                    aria-label="recipe"/>
                                        }
                                        title={player.name}
                                        subheader={<Typography variant="subtitle1" component="div">
                                            <Stars fontSize="small" style={{
                                            verticalAlign: 'text-top'
                                        }}/> {player.score}
                                        </Typography>}
                                    />
                                </Card>
                            </Badge>}
                        )
                }
            </Stack>
        );
    }
}

export default PlayerList