import React from "react";
import Slider from "@mui/material/Slider";
import {colors} from "@mui/material";

const {Component} = require("react");

class Progress extends Component {
    constructor(props) {
        super(props);
        let max = props.quizState.payload.progress.total;
        let marks = [];
        for (let i = 1; i <= max; i++) {
            marks.push({
                value: i,
                label: i,
            });
        }

        this.state = {
            marks: marks
        }
    }

    render() {
        return <Slider
            aria-label="Custom marks"
            defaultValue={1}
            value={this.props.quizState.payload.progress.current}
            step={1}
            min={1}
            max={this.state.marks.length}
            sx={{color: colors.common.white}}
            marks={this.state.marks}
        />
    }
}

export default Progress