import React, {Component} from "react";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import * as color from "@mui/material/colors";
import Fade from "@mui/material/Fade";
import Avatar from "@mui/material/Avatar";
import PlayerList from "../player-list/player-list";
import Chip from "@mui/material/Chip";
import {Stars} from "@mui/icons-material";

class RoundShowResults extends Component {
    render() {
        let answers;
        let questionVariant = this.props.quizState.payload.quiz.question.variant;
        if (questionVariant === 0) {
            answers = this.props.quizState.payload.quiz.question.answers;
        } else {
            answers = this.props.quizState.payload.quiz.question.popularAnswers
                .sort((a1, a2) => a2.score - a1.score);
        }

        return (
            <Container>
                <Stack sx={{marginTop: '50px'}} direction="row">
                    {
                        this.props.quizState.payload.quiz.question.image &&
                        <div><img style={{
                            width: '300px',
                            height: "auto",
                            borderRadius: '10px',
                            margin: '10px 20px 20px 0px'
                        }}
                             src={
                                 window.location.protocol === "http:"
                                     ? `http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_API_PORT}${this.props.quizState.payload.quiz.question.image}`
                                     : `${window.location.protocol}//${window.location.host}${this.props.quizState.payload.quiz.question.image}`
                             }
                             alt=""/></div>
                    }
                    <Typography style={{textAlign: 'left'}} variant="h3">
                        <div dangerouslySetInnerHTML={{__html: this.props.quizState.payload.quiz.question.content}}/>
                    </Typography>
                </Stack>
                <Box sx={{marginTop: '50px'}}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                    >
                        {
                            answers
                                .map((answer, i) => {
                                    return <Fade
                                        key={i}
                                        in={true}
                                        style={{transitionDelay: `${500 * i}ms`}}
                                        timeout={1000}>
                                        <Paper sx={{
                                            padding: '30px',
                                            fontSize: '30px',
                                            boxShadow: (() => {
                                                if (questionVariant === 0) {
                                                    return `0px 0px 0px 5px ${answer.correct ? green[300] : red[300]}`
                                                }
                                            })()
                                        }}>
                                            {
                                                questionVariant === 0 && answer.correct &&
                                                <Box style={{margin: '10px 0', textAlign: 'center'}}>
                                                    <Chip icon={<Stars/>} color="success"
                                                          label={this.props.quizState.payload.quiz.score}/>
                                                </Box>
                                            }
                                            {
                                                questionVariant === 1 && answer.score !== 0 &&
                                                <Box style={{margin: '10px 0', textAlign: 'center'}}>
                                                    <Chip icon={<Stars/>} color="success" label={answer.score}/>
                                                </Box>
                                            }
                                            <div dangerouslySetInnerHTML={{__html: answer.content}}/>
                                            <Stack
                                                sx={{marginTop: '20px'}}
                                                direction="row"
                                                justifyContent="center"
                                                spacing={2}
                                            >
                                                {
                                                    answer.playerDeviceIds
                                                    && answer.playerDeviceIds
                                                        .map((id, i) => {
                                                            let player = this.props.quizState.players
                                                                .filter(p => p.deviceId === id)[0];

                                                            return <Avatar
                                                                key={i} {...PlayerList.stringAvatar(player, i)}
                                                                sx={{bgcolor: color[player.color][500]}}
                                                                aria-label="recipe"/>
                                                        })
                                                }
                                            </Stack>
                                        </Paper>
                                    </Fade>
                                })
                        }
                    </Stack>
                </Box>
            </Container>
        )
    }
}

export default RoundShowResults