import React, {Component} from "react";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import TvIcon from '@mui/icons-material/Tv';


class LookScreen extends Component {
    render() {
        return <Container maxWidth="xs">
            <Typography
                component="h1"
                variant="h5"
                align="center"
                marginTop="50px"
                color="text.primary"
                gutterBottom
            >
                <TvIcon sx={{ fontSize: 100 }}/>
            </Typography>
            <Typography
                component="h1"
                variant="h5"
                align="center"
                marginTop="50px"
                color="text.primary"
                gutterBottom
            >
                {this.props.text}
            </Typography>
        </Container>
    }
}

export default LookScreen