import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Routes, Route} from "react-router";
import Player from "./player/player";
import Master from "./master/master";

ReactDOM.render(
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Master />} />
              <Route path="/player" element={<Player />} />
          </Routes>
      </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
